import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql, Link} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import {createMarkup} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";

import CarouselWrapper from '../../../components/carousel/CarouselWrapper';
import Project01 from './images/project/04-2-project-01a.jpg';
import Project02 from './images/project/04-2-project-02a.jpg';
import Project03 from './images/project/04-2-project-03a.jpg';
import Project04 from './images/project/04-2-project-04a.jpg';
import Project05 from './images/project/04-2-project-05a.jpg';
import Project06 from './images/project/04-2-project-06a.jpg';
import Project07 from './images/project/04-2-project-07a.jpg';
import Project08 from './images/project/04-2-project-08a.jpg';
import Project09 from './images/project/04-2-project-09a.jpg';

import patApplications from './images/applications/patient-app.jpg';
import staffRooms from './images/applications/staff-locker.jpg';
import medCarts from './images/applications/med-carts.jpg';
import examRooms from './images/applications/exam-room.jpg';

import posterDigilink from "./images/poster-product-healthcare.jpg";
import posterHealthcare from "./images/poster-healthcare.jpg";

import CustomerVideoWithList from "../../../components/solutions/CustomerVideoWithList";
import CustomerVideo from '../../../components/solutions/CustomerVideo';
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";

const IndexPage = () => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;

  const projectImages = [
    {
      original: Project01,
      originalAlt: 'Cabinets Secured with Digilock 4G at Erasmus MC',
      thumbnailAlt: 'Cabinets Secured with Digilock 4G at Erasmus MC thumbnail',
      thumbnail: Project01,
      thumbnailLabel: 'Erasmus MC',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project02,
      originalAlt: 'Aspire Electronic Locks Installed at the Deltares Research Institutes',
      thumbnailAlt: 'Aspire Electronic Locks Installed at the Deltares Research Institutes thumbanail',
      thumbnail: Project02,
      thumbnailLabel: 'Deltares Research Institute',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project03,
      originalAlt: 'St Antonius RFID lockers',
      thumbnailAlt: 'St Antonius RFID lockers thumbnail',
      thumbnail: Project03,
      thumbnailLabel: 'St Antonius',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project04,
      originalAlt: 'VvAA keypad lockers',
      thumbnailAlt: 'VvAA keypad lockers thumbnail',
      thumbnail: Project04,
      thumbnailLabel: 'VvAA',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project05,
      originalAlt: 'Freezelab keypad lockers',
      thumbnailAlt: 'Freezelab keypad lockers thumbnail',
      thumbnail: Project05,
      thumbnailLabel: 'Freezlab',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project06,
      originalAlt: 'Roche Pharmaceuticals RFID lockers',
      thumbnailAlt: 'Roche Pharmaceuticals RFID lockers thumbnail',
      thumbnail: Project06,
      thumbnailLabel: 'Roche Pharmaceuticals',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project07,
      originalAlt: 'Lonza keypad lockers',
      thumbnailAlt: 'Lonza keypad lockers thumbnail',
      thumbnail: Project07,
      thumbnailLabel: 'Lonza',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project08,
      originalAlt: 'SBOH keypad lockers',
      thumbnailAlt: 'SBOH keypad lockers thumbnail',
      thumbnail: Project08,
      thumbnailLabel: 'SBOH',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project09,
      originalAlt: 'Distribution Systems International Medical Cart Secured with Horizontal Body Versa Mini RFID',
      thumbnailAlt: 'Distribution Systems International Medical Cart Secured with Horizontal Body Versa Mini RFID thumbnail',
      thumbnail: Project09,
      thumbnailLabel: 'Distribution Systems Intl',
      productLink: returnLocaleURL(sl, '/products/')
    }
  ];
  const projectAsides = [
    '<span>the netherlands</span><br>' +
    '<h2>Erasmus MC</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '/">Digilock 4G Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Deltares Research Institutes</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>St Antonius</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Versa Standard RFID</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>VvAA</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Freezlab</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Sola Keypad</a></p>',

    '<span>switzerland</span><br>' +
    '<h2>Roche Pharmaceuticals</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Axis RFID</a></p>',

    '<span>switzerland</span><br>' +
    '<h2>Lonza Bioscience</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Sola Keypad</a></p>',

    '<span>australia</span><br>' +
    '<h2>Maroondah</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',

    '<span>United States</span><br>' +
    '<h2>Distribution Systems Intl</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Versa Mini RFID</a></p>'
  ];
  const ProjectAside = _ => <aside className={'dynamic-aside'}
                                   dangerouslySetInnerHTML={createMarkup(projectAsides[0])}/>;
  const solutions = [
    {
      img: patApplications,
      label: 'patient_applications',
      alt: 'patient_applications',
      bg: 'light'
    },
    {
      img: staffRooms,
      label: 'staff_locker_rooms',
      alt: 'staff_locker_rooms',
      bg: 'light'
    },
    {
      img: medCarts,
      label: 'medical_carts',
      alt: 'medical_carts',
      bg: 'light'
    },
    {
      img: examRooms,
      label: 'exam_rooms',
      alt: 'exam_rooms',
      bg: 'light'
    }

  ];
  return (
      <Layout>
        <Seo title={t('solutions_healthcare')}
             description={t('des_solutions_healthcare')}/>
        <div className="solutions healthcare">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-healthcare.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Healthcare"
            />
            <h1>
              <Trans>
                healthcare
              </Trans>
              <span><Trans>healthcare_subhead</Trans></span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideoWithList
                title={'customer_video_healthcare_title'}
                list={['customer_video_healthcare_list_l1',
                  'customer_video_healthcare_list_l2',
                  'customer_video_healthcare_list_l3',
                  'customer_video_healthcare_list_l4',
                  'customer_video_healthcare_list_l5',
                  'customer_video_healthcare_list_l6']}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/qr9hU9BzLECLh-UmnWrnKQ/QBYO3t9wOEGNtUvfl0spKQ/Widescreen720p/May%202020%20Digilock%20Healthcare%20V4.mp4'}
                poster={posterDigilink}
            />
          </section>
          <ElectronicLockLineup showPivot={true} showCurve={true} showAspire={true} showVersa={true} showOrbit={true} showCTA={true} label={'healthcare_lineup_label'} />
          <section className="industry-carousel">
            <div className="container">
              <h2><Trans>healthcare_carousel_title</Trans></h2>
              <CarouselWrapper
                  items={projectImages}
                  asides={projectAsides}
                  projectAside={<ProjectAside/>}
                  showThumbnails={true}
                  showBullets={true}
                  dynamicAside={true}
                  allowImageClick={false}
              />
            </div>
          </section>
          <br/><br/><br/><br/>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterHealthcare}
                title={'video_healthcare_title'}
                copy={'video_healthcare_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/BuS8MlhClUiTr6cCYDZkqQ/R2LeM-Kc2UOhl-HNzLowhQ/Widescreen720p/healthcare.mp4'}
            />
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions}/>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
